<template>
    <div>
    <b-card>
      <form @submit.prevent="changeSerDetail" action="">
        <b-row>
            <b-col cols="2"><label>Service Name <span style="color: red">*</span> </label></b-col>
            <b-col>
            <b-input
                  type="text"
                  label=""
                  placeholder="Service Name"
                  v-model="serData.title"
                  maxlength="250"
                  required
                >
            </b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="2"><label>Service Provider <span style="color: red">*</span> </label></b-col>
            <b-col>
                <multiselect
                :options="providerlist"
                label="name"
                v-model="selectedProvider"
                placeholder="select service provider"
                required
                >
                </multiselect>
            </b-col>
        </b-row>
        <hr>

        <b-row>
            <b-col cols="2">
                <label>Category <span style="color: red">*</span> </label>
            </b-col>
            <b-col>
                <multiselect
                :options="categoryArray"
                label="name"
                v-model="selectedCategory"
                placeholder="Select Category"
                required
                >
                </multiselect>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="2">
                <label>Ad Type <span style="color: red">*</span> </label>
            </b-col>
            <b-col>
                <multiselect
                :options="adTypeArray"
                label="name"
                v-model="selectedAdType"
                placeholder="Select Ad Type"
                required
                >
                </multiselect>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="2">
                <label>Ad Url <span style="color: red">*</span> </label>
            </b-col>
            <b-col>
                <b-input
                    type="text"
                    label=""
                    placeholder="Ad Url"
                    v-model="serData.adUrl"
                >
                </b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="2"><label>Payment Info</label></b-col>
            <b-col>
                <b-input
                    type="text"
                    label=""
                    placeholder="Payment Info"
                    v-model="serData.paymentInfo"
                >
                </b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="2">
                <label>Price Point Info</label>
            </b-col>
            <b-col>
                <b-input
                    type="text"
                    label=""
                    placeholder="Price Point Info"
                    v-model="serData.pricePointInfo"
                >
                </b-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col class="text-center">
                <b-button
                    type="submit"
                    :disabled="isBusy"
                    variant="primary"
                    >
                    {{ isEdit ? "Edit" : "Add" }}
            </b-button>
            </b-col>
        </b-row>
      </form>
        </b-card>
    </div>
  </template>
  
  <script>
  import SerService from "../../../../services/ServiceInfo";
  import Multiselect from "vue-multiselect";
  import ServiceProviderServices from "../../../../services/ServiceProviderServices";
  import config from "../../../../services/globalLit";
  export default {
    props:{sddetail:Object,sdModal:Boolean,isEdit:Boolean},
    components: {
      Multiselect,
    },
    data() {
      return {
        isBusy: false,
        serData: {},
        providerlist: [],
        selectedProvider: [],
        //1: Games 2: Video 3: Media 4: Sports 5: Mixed 6: e-Learning
        categoryArray: config.categorylist,
        adTypeArray: config.adTypeArray,
        selectedCategory: [],
        selectedAdType: [{ "id": 1, "name": "Banner" },]
      };
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        //get providerlist
        let spData = await ServiceProviderServices.get();
        if (spData.result) {
          this.providerlist = spData.data.reverse();
          if(this.isEdit){
            if (this.sddetail) {
            this.serData = this.sddetail;
            this.isEdit = true;
            //   this.providerlist.map((e) => {console.log('here')})
            this.selectedProvider = this.providerlist.find(
                (e) => this.sddetail.spid == e._id
            );
            
            this.selectedCategory = config.categorylist.find(
                (e) => this.sddetail.category == e.id
            );
            this.selectedAdType = config.adTypeArray.find(
                (e) => this.sddetail.adtype == e.id
            );
    
            } else {
            this.isEdit = false;
            this.selectedProvider = {};
            this.serData = {};
            this.selectedCategory = {};
            this.selectedAdType = {}
            }
      }
        }
      },
      async changeSerDetail() {
        if(this.validate()){
          if (this.isEdit) {
            await this.editSerData();
          } else {
            await this.addService();
          }
        }
      },
      async editSerData() {
        this.isBusy = true;
        let spid = this.selectedProvider._id;
        let category = this.selectedCategory["id"];
        let adtype = this.selectedAdType["id"];
        let payload = {
          sid: this.serData._id,
          changeBody: {
            title: this.serData.title,
            spid: spid,
            category,
            adtype,
            pricePointInfo: this.serData.pricePointInfo,
            paymentInfo: this.serData.paymentInfo,
            adUrl: this.serData.adUrl
          },
        };
  
        let response = await SerService.update(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000,
        onComplete: () => {
          this.$emit('closeSdDetail')
          this.isBusy = false;
        }});
      },
      async addService() {
        this.isBusy = true;
        let spid = this.selectedProvider._id;
        this.serData.spid = spid;
        this.serData.category = this.selectedCategory["id"];
        this.serData.adtype = this.selectedAdType["id"];
  
        let response = await SerService.add(this.serData);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000,
        onComplete: () => {
          this.$emit('closeSdDetail')
          this.isBusy = false;
        }});
      },
      validate(){
        const showToast = (message) => {
          this.$toasted.show(message, { theme: 'outline', position: 'bottom-center', type: 'error', duration: 2000 });
        };
        if(this.serData.title == null || this.serData.title == ''){
          showToast('Please enter service name');
          return false;
        }
        if(this.selectedProvider == null || this.selectedProvider.length == 0){
          showToast('Please select service provider');
          return false;
        }
        if(this.selectedCategory == null || this.selectedCategory.length == 0){
          showToast('Please select category');
          return false;
        }
        if(this.selectedAdType == null || this.selectedAdType.length == 0){
          showToast('Please select ad type');
          return false;
        }
        if(this.serData.adUrl == '' || config.isValidUrl(this.serData.adUrl) == false){
          showToast('Please enter valid url');
          return false;
        }
        return true;
      }
    },
  };
  </script>
 
  <style scoped>
  .hidden_header {
    display: none;
  }
  div {
    margin: auto 0;
    width: 100%;
  }
  </style>